import React from "react";
import "./CTA.css";
import myPhoto from "../../assets/possibility.png";
export const CTA = () => {
  return (
    <div className="gpt3__CTA" id="home">
      <div className="gpt3__CTA-content">
        {/*<div className="gpt3__CTA-content_image">
          <img src={myPhoto}/>  
  </div>*/}
        
        <div className="gpt3__CTA-content_text">
        <p>ENROLL YOUR CHILD</p>
        <p>
          Enroll today to begin your child's education progress
        </p>
        </div>
      </div>
      <div className="gpt3__CTA-button">
       <a href="#contacts">
          <button type="button">APPLY NOW</button>
        </a>
      </div>
    </div>
  );
};
