import "./App.css";
import { Navbar } from "./components/";
import { Footer, Background, Header,CTA, Welcome } from "./containers/";
function App() {
  return (
    <div className="App">
      <Navbar />
      <Header />
      <CTA/>
      <Welcome/>
      {/*<Background />
      <Experience />
  <Footer />*/}
    </div>
  );
}

export default App;
