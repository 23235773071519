import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCodeBranch,
  faCodeCommit,
  faCodePullRequest,
  faCodeMerge,
  faPhone,
  faLaptopFile,
  faBriefcase,
  faLaptopCode,
  faBook,
  faBrain,
  faMailBulk,
} from "@fortawesome/free-solid-svg-icons";
import "./Navbar.css";

const Small_Menu = () => (
  <>
    <p>
      <a href="#Projects">
      <FontAwesomeIcon icon={faLaptopCode} /> Gallery 
      </a>
    </p>
    <p>
      <a href="#Skills">
      <FontAwesomeIcon icon={faBrain} /> Events 
      </a>
    </p>
    <p>
      <a href="#CTA">
      <FontAwesomeIcon icon={faBook} /> Meetings 
      </a>
    </p>
    <p>
      <a href="#CTA">
      <FontAwesomeIcon icon={faLaptopFile} /> Schedule 
      </a>
    </p>
  </>
);
export const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  return (
    <div className="gpt3__navbar section__padding">
      <div className="gpt3__navbar-brand">
        <div className="gpt3__navbar-brand_logo" id="logo">
          <span>
            <a href="https://github.com/eliya27">Dares Day care</a>
          </span>
        </div>
      </div>
      <div className="gpt3__navbar-highlights">
        <Small_Menu />
      </div>
      <div className="gpt3__navbar-contacts">
        <span>
          <a href="#contacts">
            <FontAwesomeIcon icon={faPhone} />
          </a>
        </span>
        <span>
          <a href="#contacts">
            <FontAwesomeIcon icon={faMailBulk} />
          </a>
        </span>
        <span>
          <a href="#contacts">
            <FontAwesomeIcon icon={faCodePullRequest} />
          </a>
        </span>
      </div>
      <div className="gpt3__navbar-menu">
        {toggleMenu ? (
          <FontAwesomeIcon
            icon={faCodeBranch}
            onClick={() => setToggleMenu(false)}
          />
        ) : (
          <FontAwesomeIcon
            icon={faCodeCommit}
            onClick={() => setToggleMenu(true)}
          />
        )}

        {toggleMenu && (
          <div className="gpt3__navbar-menu_container scale-up-center">
            <div className="gpt3__navbar-menu_highlights">
              <Small_Menu />
            </div>
            <div className="gpt3__navbar-menu_container-contacts">
              <span>
                <a href="#contacts">
                  <FontAwesomeIcon icon={faPhone} />
                </a>
              </span>

              <span>
                <a href="#contacts">
                  <FontAwesomeIcon icon={faMailBulk} />
                </a>
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
