import React from "react";
import "./Welcome.css";
import myPhoto from "../../assets/kids-study.jpg";
export const Welcome = () => {
  return (
    <div className="gpt3__welcome" id="home">
      <div className="gpt3__welcome-header">
        <p>Welcome Note</p>
      </div>
      <div className="gpt3__welcome-content">
        <div className="gpt3__welcome-content_desc">
          <p>We have been active for more than 10 years helping 
            children to grow extraordinary in all dimensions we have quality 
            facilities and professional teachers to help 
            your child achieve his dreams and have fun. Our school enroll children from as early age as 3.5 years up to 4 years, to us it's not only about class achievemnts but all around growth of our students is our top priority.
         </p>
         <span>"The first five years of development have so much to do with how the next 80 transpire"</span>
        </div>
        {/*<div className="gpt3__welcome-content_image">
         <img src={myPhoto}/>
  </div>*/}
      </div>
    </div>
  );
};
