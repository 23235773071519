import React from "react";
import "./Header.css";
import myPhoto from "../../assets/possibility.png";
export const Header = () => {
  return (
    <div className="gpt3__header section__padding" id="home">
      <div className="gpt3__header-content">
        <h3>Welcome to Dares day care</h3>
        <p>
          A place for all your little ones
        </p>
        <a href="#contacts">
          <button type="button">Enroll Now</button>
        </a>
      </div>
    </div>
  );
};
